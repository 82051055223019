.done {
    color: #00ff9d;
    font-size: 20px;
    line-height: 30px;
}

.wallet-block {
    color: #00ff9d;
    font-size: 18px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    padding-left: 5px;
    padding-right: 5px;
}

.linking-text {
    color: #00ff9d;
    font-size: 20px;
    margin-top: 28px;
}