.App {
  text-align: center;
}

.App-logo {
  display: flex;
  position: absolute;
  height: 32px;
  pointer-events: none;
  top: 14px;
  left: 32px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.action-btn {
  color: white;
  font-family: 'Play', sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: auto;
  width: 100%;
  text-align: left;
  line-height: 30px;
  background: linear-gradient(270deg, #28e5ff -20%, #ff258e 100%);
  display: flex;
  gap: 15px;
  padding: 10px;
  align-items: center;
  margin-top: 10px;
  padding-left: 20px;

  border-radius: 0;
  border-width: 1px;
  border-image: linear-gradient(to left, #26ddf5, #ff258e) 1;
}

.action-btn:hover {
  background: #ff258e;
  border-image: linear-gradient(to left, #26ddf5, #26ddf5) 1;
}

.error-text {
  color: red;
  font-size: 15px;
  margin-top: 20px;
}
