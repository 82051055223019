.modal {
    background: linear-gradient(270deg, #072764 -50%, #570549 110%);
    height: auto;
    width: auto;
}

h3 {
    color: white;
    font-size: 20px;
    margin-left: 100px;
    margin-right: 100px;
}

.close-btn {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    font-size: 30px;
    cursor: pointer;
    margin: 10px;
}

.close-btn:hover {
    color: white;
    background-color: rgba(0, 0, 0, 0.253);
    border-radius: 10%;
}

.provider-btn {
    color: white;
    font-family: 'Play', sans-serif;
    font-size: 16px;
    font-weight: 700;

    height: auto;
    width: 100%;
    text-align: left;
    line-height: 30px;
    background: linear-gradient(270deg, #072764 -20%, #570549 80%);

    display: flex;
    gap: 15px;
    padding: 10px;
    align-items: center;
    
    margin-top: 10px;
    padding-left: 20px;
}

.provider-btn:hover {
    background: rgba(0, 0, 0, 0.404);
}

.gradient-holder {
    border-radius: 0;
    border-width: 1px;
    border-image: linear-gradient(to left, #00ffff, #ff00ff) 1;
}
