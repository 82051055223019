.action-btn {
    text-align: center;
    width: 180px;
    justify-content: center;
    padding: 5px;
}

.disconnect-btn {
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    border-image: linear-gradient(to left, #072764, #570549) 1;
    border-width: 2px;
}

.disconnect-btn:hover {
    background: rgba(0, 0, 0, 0.6);
    border-image: linear-gradient(to left, #ff258e, #ff258e) 1;
    border-width: 2px;
}

.wallet-box{
    width: 180px;
    font-size: 22px;
    background: linear-gradient(to left, #5983ac, #5983ac);
    border-width: 1px;
    padding-top: 6px;
    padding-bottom: 6px;
}